<template>
  <div>
    <div class="row">
      <div class="offset-md-2 col-xl-8">
        <notifications group="notification" position="top right" />

        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group input-group-lg">
                  <input
                    id="newLoggerImei"
                    disabled
                    v-model="logger.imei"
                    type="text"
                    class="form-control form-control-solid"
                    :placeholder="logger.imei"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group input-group-lg mb-0">
                  <input
                    id="newLoggerSerialNumber"
                    v-model="logger.serialNumber"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Serijski broj"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group-lg mb-0">
                  <input
                    id="newLoggerMsisdnNumber"
                    v-model="logger.msisdnNumber"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="MSISDN"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <button
              @click="submit"
              type="reset"
              :disabled="!logger.imei"
              class="btn btn-primary btn-lg btn-block"
            >
              Uzmjeni Logger
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'LoggersEdit',
  data() {
    return {
      logger: {
        imei: null,
        serialNumber: null,
        msisdnNumber: null,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loggers', route: '/loggers/list' },
      { title: 'Edit Logger' },
    ])

    ApiService.get('/loggers', this.$route.params.id).then(({ data }) => {
      this.logger.imei = data.imei
      this.logger.serialNumber = data.serialNumber
      this.logger.msisdnNumber = data.msisdnNumber
    })
  },
  methods: {
    submit() {
      ApiService.update('/loggers', this.$route.params.id, this.logger).then(
        () => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Logger neuspjesno izmjenjen.',
          })

          setTimeout(() => {
            this.$router.push({ name: 'loggers-list' })
          }, 200)
        }
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
